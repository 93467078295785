import { httpClient } from "../httpClient";
export default class SessionService {
    baseUrl = "Session"

    fetchActiveSession = async (customerToken) => {
        if(customerToken) {
            let {data} = httpClient.get(`${this.baseUrl}/GetActiveSession/${customerToken}`)
            return data;
        }
        return null;
    }
    listSessions = async (customerToken) => {
        if(customerToken) {
            let {data} = await httpClient.get(`${this.baseUrl}/ListSessions/${customerToken}`)
            return data;
        } else {
            console.log("no customer token")
        }
        return null;
    }
    archiveCurrentSession = async (customerToken) => {
        if (customerToken) {
            let { data } = await httpClient.post(`${this.baseUrl}/ArchiveCurrentSession/${customerToken}`);
            return data;
        }
        return [];
    }
    saveSessionRules = async (customerToken, session) => {
        if (customerToken) {
            let { data } = await httpClient.post(`${this.baseUrl}/SetSessionRules/${customerToken}`, session);
            return data;
        }
        return [];
    }
}