import { createTheme } from "@mui/material";

const primary = '#3279FD';
const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: primary,
          contrastText: 'white',
          accent: '#adaba5'
        },
        secondary: {
          main: '#F5C66E',
        },
        nuggets: {
          answered: '#5E37FD',
          unanswered: '#5B95FE',
          donationAmount: '#00AB00',
          controls: '#5E37FD',
          disabledControls: '#BAB9B9',
          leftBorder: primary,
      },
    }
});

export default theme;