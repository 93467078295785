import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useParams } from 'react-router';
import Session from '../classes/Session';
import { APIFactory } from '../api/index';
import { useUserData } from './UserProvider';


const ActiveSessionContext = React.createContext();
const useActiveSession = () => React.useContext(ActiveSessionContext);

const ActiveSessionProvider = ({ children }) => {
    const sessionService = APIFactory.get("session");
    const commentsService = APIFactory.get("comments");
    const { customerToken } = useParams();
    const [token, setToken] = React.useState('');
    const [session, setSession] = React.useState({});
    const [sessionIndex, setSessionIndex] = React.useState('');
    const { siteUser, isAPIConfigured, roles } = useUserData();
    const [nuggets, setNuggets] = useState([]);
    const [test, setTest] = useState('');
    const [sessions, setSessions] = useState([]);
    const [nuggetMap, setNuggetMap] = useState({})
    const [isModerator, setIsModerator] = useState(false);

    React.useEffect(() => {
        const fetchActiveSession = async () => {
            //do something
            // let activeSession = await sessionService.fetchActiveSession(customerToken);
            if (false) {
                setSession('');
            } else {
                //show some notification to the user
            }
        }
        if (customerToken && isAPIConfigured && roles) {
            setToken(customerToken);

            document.title = "SimpleTip | " + customerToken

            fetchActiveSession();
        }
    }, [customerToken, isAPIConfigured])

    useEffect(() => {   
        if(roles && customerToken) {
            setIsModerator(roles.includes(`Moderator ${customerToken}`))
        }
    }, [customerToken, roles])

    useEffect(() => {
       
        if (siteUser && siteUser.sub) {
            fetchComments();
        }
    }, [siteUser, isAPIConfigured])

    useEffect(() => {
       
        if (siteUser && siteUser.sub) {
            fetchSessions();
        }
    }, [siteUser, isAPIConfigured])

    const fetchComments = async () => {
        try {
            let nugs = await commentsService.fetchCurrentComments(customerToken);
            setNuggets(nugs.length == 0 ? [{doNotShow:true}] : nugs);
        } catch (e) {

        }

    }

    const fetchSessions = async () => {
        try {
            let sessions = await sessionService.listSessions(customerToken);
            setSessionIndex(0)
            setSessions(sessions);
            setSession(sessions[0])
        } catch (e) {

        }

    }

    const loadPreviousSession = async () => {
        try {
            if (sessionIndex >= sessions.length)
                return
            let nugs = await commentsService.fetchNuggetsForSession(customerToken, sessions[sessionIndex + 1].sessionID);
            setSessionIndex(sessionIndex + 1)
            setNuggets(nugs.length == 0 ? [{doNotShow:true}] : nugs);
            setSession(sessions[sessionIndex + 1])
        } catch (e) {
            console.log("error - " + e)
        }

    }

    const loadNextSession = async () => {
        try {
            if (sessionIndex == 0)
                return
            let nugs = await commentsService.fetchNuggetsForSession(customerToken, sessions[sessionIndex - 1].sessionID);
            setSessionIndex(sessionIndex - 1)
            setSession(sessions[sessionIndex - 1])
            setNuggets(nugs.length == 0 ? [{doNotShow:true}] : nugs);
        } catch (e) {
            console.log("error - " + e)
        }

    }

    const saveSessionRules = async (session) => {
        try {
            await sessionService.saveSessionRules(customerToken, session);
        } catch (e) {
            console.log("error - " + e)
        }

    }
 
    const markNuggetAnswered = async (nuggetId) => {
        try {
            if (nuggetId) {
                await commentsService.markNuggetAnswered(customerToken, nuggetId)
            }
        } catch (e) {
            console.log("error - " + e)
        }
    }

    const markNuggetUnanswered = async (nuggetId) => {
        try {
            if(nuggetId){
                await commentsService.markNuggetUnanswered(customerToken, nuggetId);
            }
        } catch(e) {
            console.log("error - " + e)
        }
   }

    const toggleNuggetDoNotArchive = async (nuggetId) => {
        try {
            if(nuggetId){
                await commentsService.toggleNuggetDoNotArchive(customerToken, nuggetId);
            }
        } catch(e) {
            console.log("Error toggleNuggetDoNotArchive")
        }
    }

    const refreshPage = async () => {
        try {
             setTest('' + Math.random())
        } catch (e) {
        }
    }

    const archiveCurrentSession = async () => {
        try {
             await sessionService.archiveCurrentSession(customerToken)
             await fetchSessions();
             await fetchComments();
        } catch (e) {
            console.log("Error archiveCurrentSession")
        }
    }

    const value = {
        customertoken: token,
        session: session,
        sessionIndex: sessionIndex,
        nuggets: nuggets,
        sessions: sessions,
        nuggetMap: nuggetMap,
        isModerator: isModerator,
        fetchCurrentComments: fetchComments,
        loadPreviousSession: loadPreviousSession,
        loadNextSession: loadNextSession,
        archiveCurrentSession: archiveCurrentSession,
        markNuggetAnswered: markNuggetAnswered,
        markNuggetUnanswered: markNuggetUnanswered,
        toggleNuggetDoNotArchive: toggleNuggetDoNotArchive,
        saveSessionRules: saveSessionRules,
        refreshPage : refreshPage
    }
    return <ActiveSessionContext.Provider value={value} children={children} />
}

export { ActiveSessionProvider, useActiveSession }