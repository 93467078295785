import React, { useEffect, useState } from 'react'
import Nugget from '../../classes/Nugget';
import { Card, CardHeader, CardContent, Grid, Divider, Typography, Skeleton } from '@mui/material';
import NuggetMetaData from './NuggetMetaData';
import NuggetControls from './NuggetControls';
const NuggetDisplay = ({ nugget, index, onToggleAnswered }) => {

    const handleToggleAnswered = () => {
        onToggleAnswered && onToggleAnswered(index)
    }

    return (
        <>
            {nugget?.isVisible ?
                <Card sx={{ borderLeft: '.5rem solid', borderColor: 'nuggets.leftBorder' }} >

                    <CardContent>
                        <Grid container spacing={2} flexDirection='column'>
                            <Grid item xs={12}>
                                {
                                    nugget ?
                                        <NuggetMetaData nugget={nugget} /> :
                                        <Skeleton />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    nugget ?
                                        <Typography>{nugget?.textContent}</Typography> :
                                        <>
                                            <Skeleton />
                                            <Skeleton />
                                            <Skeleton />
                                        </>
                                }
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <NuggetControls nugget={nugget} onToggleAnswered={handleToggleAnswered} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                : <></>}
        </>

    )
}

export default NuggetDisplay