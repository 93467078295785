import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Grid } from '@mui/material';
import NuggetDisplay from './NuggetDisplay';
import NuggetMetaData from './NuggetMetaData';
import { useMediaQuery } from '@mui/material';
import NuggetContainerActions from './NuggetContainerActions'
import { DefaultNuggetFilters } from '../../constants/DefaultNuggetFilters';
import { debounce } from '@mui/material';

const NuggetContainer = ({ nuggets, sessions, sessionIndex, sx, onRefreshFeed, onArchiveSession, onPreviousSession, onNextSession }) => {
    const [managedNuggets, setManagedNuggets] = useState(nuggets ?? []);
    const [filteredNuggets, setFilteredNuggets] = useState(nuggets);
    const isMobileViewPort = useMediaQuery((theme) => theme.breakpoints.down('lg'))
    const [filtersToApply, setFilterstoApply] = useState({ ...DefaultNuggetFilters })

    useEffect(() => {
        if (nuggets && nuggets.length > 0) {
            setManagedNuggets(nuggets);
        }
    }, [nuggets])


    const getVisibility = (nugget) => {
        let showAnswered = filtersToApply['showAnswered']
        let showTipeee = filtersToApply['showTipeee'];
        let showYoutube = filtersToApply['showYoutube'];
        let showOther = filtersToApply['showOther'];
        let textFilter = filtersToApply['textContent']?.toLowerCase();
        let userNameFilter = filtersToApply['userName']?.toLowerCase();
        let showAllNuggets = filtersToApply['showAllNuggets']

        return nugget && ((!nugget.isAnswered || showAnswered) &&
                ((nugget.source.toUpperCase() === "TIPEEESTREAM" && showTipeee) ||
                (nugget.source.toUpperCase() === "YOUTUBE" && showYoutube) || nugget.source === "" || showOther) &&
                (nugget.textContent.toLowerCase().indexOf(textFilter) > -1)  &&
                (nugget.userName?.toLowerCase().indexOf(userNameFilter) > -1) || showAllNuggets);
    }



    const handleToggleAnswered = (index) => {
        let targetNugget = managedNuggets[index];
        managedNuggets[index] = {...managedNuggets[index], isAnswered: !targetNugget.isAnswered, isVisible: getVisibility(targetNugget)}
        setManagedNuggets([...managedNuggets])
        //applyFilters(filtersToApply);

    }
    const renderNuggets = () => {
        if (managedNuggets?.length == 0) {
            return (
                <></>
            )
        } else {
            if (managedNuggets[0].doNotShow) {
                return (
                    <></>
                )
            } else {
                return managedNuggets?.map((nugget, index) => {
                    nugget.isVisible = getVisibility(nugget);
                    if (nugget.isVisible) {
                        return (
                            <Grid item key={nugget.nuggetID} xs={isMobileViewPort ? 12 : 6}>
                                <NuggetDisplay nugget={nugget} index={index} onToggleAnswered={handleToggleAnswered}  />
                            </Grid>
                        );
                    }
                });
            }
            
        }
    }

    const handleApplyFilters = (filters) => {
        setFilterstoApply({ ...filters });
    }

    return (
        <>
            <NuggetContainerActions onApplyFilters={handleApplyFilters}
                                    onRefreshFeed={() => { onRefreshFeed && onRefreshFeed() }}
                                    onArchiveSession={() => { onArchiveSession && onArchiveSession() }}
                                    showOnPreviousSession={() => sessionIndex < sessions.length - 1}
                                    onPreviousSession={() => { onPreviousSession && onPreviousSession() }}
                                    showOnNextSession={() => { return sessionIndex > 0 }}
                                    onNextSession={() => { onNextSession && onNextSession() }}
                                    nuggetCount={managedNuggets.length} />
            <Grid container spacing={2} flexDirection={'row'} sx={{ ...sx }}>
                {renderNuggets()}
            </Grid>
        </>
    )
}

export default NuggetContainer