import { httpClient } from "../httpClient";

export default class CommentsService {
    baseUrl = 'Nugget'

    fetchCurrentComments = async (customerToken) => {
        if (customerToken) {
            let { data } = await httpClient.get(`${this.baseUrl}/GetCurrentNuggets/${customerToken}/${true}`);
            return data;
        }
        return [];
    }

    submitComment = async (customerToken) => {
        if (customerToken) {
            let { data } = httpClient.post(`${this.baseUrl}/SubmitNugget/${customerToken}`, {
                "Source": "Test",
                "SourceID": "123456789",
                "UserID": "ME",
                "CommentText": "tO tHe MoOn",
                "PaymentAmount": 420.69
            });
            return data;
        }
        return [];
    }

    fetchNuggetsForSession = async (customerToken, sessionId) => {
        if (customerToken && sessionId  ) {
            let { data } = await httpClient.get(`${this.baseUrl}/GetNuggetsForSession/${customerToken}/${sessionId}`);
            return data;
        }
        return [];
    }

    markNuggetAnswered = async (customerToken, nuggetID) => {
        if (customerToken && nuggetID) {
            let response = httpClient.post(`${this.baseUrl}/MarkNuggetAnswered/${customerToken}`, {nuggetID: nuggetID});
            return response;
        }
    }

    markNuggetUnanswered = async (customerToken, nuggetID) => {
        if (customerToken && nuggetID) {
            let response = httpClient.post(`${this.baseUrl}/MarkNuggetUnanswered/${customerToken}`, {nuggetID: nuggetID});
            return response;
        }
    }

    toggleNuggetDoNotArchive = async (customerToken, nuggetID) => {
        if (customerToken && nuggetID) {
            let { response } = await httpClient.post(`${this.baseUrl}/ToggleNuggetDoNotArchive/${customerToken}/${nuggetID}`, {});
            return response;
        }
    }
}