import React from 'react'
import {Box, Grid, Button, IconButton} from '@mui/material';
import { LoginButton } from '../Buttons';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NavButton from '../Buttons/NavButton';
import useMediaQuery from '@mui/material/useMediaQuery'
import RenderIf from '../Common/HOC/RenderIf';
import { useParams } from 'react-router';
const Nav = ({onMenuClick}) => {
  const isMediumViewPort = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const {customerToken} = useParams();
  return (

   <Grid container width="100vw" sx={{backgroundColor: 'primary.main', py: '1rem', px: isMediumViewPort ? '5rem' : '1rem'}} alignContent='left'>
     <RenderIf condition={isMediumViewPort}>
       <Grid item md={1}>
       <NavButton labelText={'ProfileIcon'} onClick={() => {alert("Coming soon!")}} color ="primary.main"/> 
       </Grid>
        <Grid item md={1}>
            <LoginButton customerToken={customerToken}/>
        </Grid>
        <Grid item md={1}>
            <NavButton labelText={'FAQs'} onClick={() => {alert("Coming soon!")}} />
        </Grid>
        <Grid item minWidth='3.5rem'>
            <NavButton labelText={'Comments'} onClick={() => {alert("Coming soon!")}} sx={{ minWidth: '3.5rem'}} />
        </Grid>
     </RenderIf>
     <RenderIf condition={!isMediumViewPort}>
      <Grid item xs={2} md={0}>
          <IconButton onClick={onMenuClick}>
            <MenuOutlinedIcon fontSize={'large'} sx={{color: 'primary.contrastText'}}/>
          </IconButton>
      </Grid>
     </RenderIf>
   </Grid>
  )
}

export default Nav