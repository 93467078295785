import React, {useEffect, useState, useContext, createContext, useLayoutEffect, useReducer} from 'react'
import { httpClient } from '../api/httpClient'
import {Auth0Provider } from '@auth0/auth0-react';
import { AuthReducer, initialState, AuthActionType } from '../Reducers/AuthReducer';



const AuthorizationContext = createContext({authDispatch: undefined});
const useAuth = () =>  useContext(AuthorizationContext);


const AuthProvider = ({children}) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [authProviderState, dispatch] = useReducer(AuthReducer, initialState);

    useEffect(() => {
            
        if(authProviderState && authProviderState.clientId && authProviderState.redirectUri && authProviderState.customerToken)
            setIsInitialized(true);

    }, [authProviderState.clientId, authProviderState.redirectUri, authProviderState.customerToken]);

    const value = {
        authDispatch: dispatch
    }
    if(isInitialized) {
        return (
            <Auth0Provider
            domain="simpletip.us.auth0.com"
            clientId={authProviderState.clientId}
            redirectUri={authProviderState.redirectUri}
            issuer='https://simpletip.us.auth0.com/'
            audience='https://api.simpletip.io'
            >
                 <AuthorizationContext.Provider children={children} value={value}/>
            </Auth0Provider>
      )
    } else {
        return <AuthorizationContext.Provider children={children} value={value}/>
    }
  

}

export {AuthProvider, useAuth}