import React from 'react'

const AuthState = {
    customerToken: '',
    clientId: '',
    redirectUri: '',
}

const AuthActionType = {
    SET_CUSTOMER_TOKEN: "SET_CUSTOMER_TOKEN",
    SET_CLIENT_ID: "SET_CLIENT_ID",
    SET_REDIRECT_URI: "SET_REDIRECT_URI",
    PURGE_STATE: "PURGE_STATE"
}

const initialState = {
    customerToken: '',
    clientId: '',
    redirectUri: '',
}

const AuthReducer = (state, action) => {
  switch(action.type) {
        case AuthActionType.SET_CUSTOMER_TOKEN:
            return {...state, customerToken: action.payload}
        case AuthActionType.SET_CLIENT_ID:
            return {...state, clientId: action.payload}
        case AuthActionType.SET_REDIRECT_URI:
            return {...state, redirectUri: action.payload}
        case AuthActionType.PURGE_STATE:
            return {...initialState}    
        default:
            return state
  }
}

export {AuthReducer, AuthState, AuthActionType, initialState}