import React, {useEffect, useState} from 'react'
import {useActiveSession, ActiveSessionProvider} from '../../Hooks/ActiveSessionProvider'
import {Box, Grid, Typography} from '@mui/material';
import { LoginButton, LogoutButton } from '../../components/Buttons';
import { useParams } from 'react-router';
import { useAuth } from '../../Hooks/AuthProvider';
import { AuthActionType } from '../../Reducers/AuthReducer';
import { APIFactory } from '../../api';
import { useUserData } from '../../Hooks/UserProvider';
import NuggetContainer from '../../components/Nuggets/NuggetContainer';
import LoadingOverlay from '../../components/chrome/LoadingOverlay';
import NuggetMetaData from '../../components/Nuggets/NuggetMetaData';


const index = () => {
    
  return (
    <ActiveSessionProvider>
        <ActiveSessionPageComponents />
    </ActiveSessionProvider>
  )
}

const ActiveSessionPageComponents = () => {
    const { siteUser, isAuthenticated, isAPIConfigured } = useUserData();
    const commentService = APIFactory.get('comments');
    const { isModerator, session, sessionIndex, nuggets, sessions, fetchCurrentComments, loadPreviousSession, loadNextSession, archiveCurrentSession, saveSessionRules } = useActiveSession();
    const { customerToken } = useParams();
    const { authDispatch } = useAuth();

    useEffect(() => {
        if (siteUser && isAuthenticated && isAPIConfigured) {
           // commentService.submitComment(customerToken)
        }
    }, [siteUser, isAPIConfigured])

    useEffect(() => {
      if(customerToken) {
        authDispatch({type: AuthActionType.SET_CUSTOMER_TOKEN, payload: customerToken});
        authDispatch({type: AuthActionType.SET_CLIENT_ID, payload: 'qX96ZAHDqLhiXEx81V4heqY00cahEzSL'});
        authDispatch({type: AuthActionType.SET_REDIRECT_URI, payload: `http://localhost:44335/${customerToken}`});
      }
    }, [customerToken])


    return (
        <>
            {isModerator ? (sessionIndex == 0 ? 
              <h2 style={{textAlign: "center"}}>Current session</h2> :
              <h2 style={{textAlign: "center"}}>Session archived on {sessions[sessionIndex].dateCreated.substring(0, sessions[sessionIndex].dateCreated.indexOf("T"))}</h2>) :
              <></>
            }
            <NuggetContainer nuggets={nuggets}
                             sessions={sessions}
                             sessionIndex={sessionIndex}
                             onRefreshFeed={() => fetchCurrentComments()}
                             onArchiveSession={() => archiveCurrentSession()}
                             onPreviousSession={() => loadPreviousSession()}
                             onNextSession={() => loadNextSession()}
                             onSaveSessionRules={(session) => saveSessionRules(session)}/>
        </>
    )
}

export default index