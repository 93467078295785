import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { SourceIdMap } from '../../constants/SourceIdMap'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InfoIcon from '@mui/icons-material/Info'
import TippeeeIcon from '../Icons/TipeeLogo'
import { formatNuggetDateTime } from '../../helpers/DateHelpers'
import STLogo from '../Icons/STLogo'
const NuggetMetaData = ({ nugget }) => {
    const getNuggetSourceIcon = () => {

        switch (nugget?.source?.toLowerCase()) {
            case "tipeeestream":
                return (
                    <TippeeeIcon />
                )
            case "youtube":
                return (
                    <YouTubeIcon />
                )
            default:
                return (
                    <div title={nugget?.source + " - " + nugget?.sourceProvider}>
                        <InfoIcon  />
                    </div>
                )
            
        }
        return <></>
    }
    return (
        <Grid container justifyContent='space-between' maxHeight='2rem'>
            <Grid container item xs={10} spacing={2}>
                <Grid item xs={4}>
                    <Typography fontStyle='italic'>{nugget?.userName}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography>{formatNuggetDateTime(nugget?.dateCreated)}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography fontWeight={'bold'} color='nuggets.donationAmount'>{`${nugget?.currencySymbol}${parseFloat(nugget?.paymentAmount).toFixed(2)}`}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={2} justifyContent='flex-end' alignItems='end'>
                <Grid item xs={12} pb={'2rem'} textAlign='right'>
                    {getNuggetSourceIcon()}
                </Grid>
            </Grid>
        </Grid>

    )
}

export default NuggetMetaData