import {useState, useEffect, createContext, useContext} from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { httpClient } from '../api/httpClient';
import { useParams } from 'react-router';

const UserContext = createContext();
const useUserData = () => useContext(UserContext);


const UserProvider = ({children}) => {
    const {user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims} = useAuth0();
    const [roles, setRoles] = useState();
    const [isModerator, setIsModerator] = useState(false);
    const [isSiteAdministrator, setIsSiteAdministrator] = useState(false);
    const [siteUser, setSiteUser] = useState(user)
    const [isAPIConfigured, setAPIConfigured] = useState(false)
    const [token, setToken] = useState();

    useEffect(() => {
        const fetchAccessToken = async () => {
            let tokenResponse = await getAccessTokenSilently({
                audience: "https://api.simpletip.io",
                scope: "openid profile"
            });
            setToken(tokenResponse);
        }
        if(getAccessTokenSilently && user && isAuthenticated){
            fetchAccessToken();
        }
    }, [user]);

    useEffect(() => {
        if(token && token.length){
            httpClient.configureClient(token);
            setAPIConfigured(true);
        }
    }, [token])

    useEffect(() => {
        const fetchIdtokenClaims = async () => {
            let claims = await getIdTokenClaims();
            let roles = claims['https://simpletip.io/roles'];
            setRoles(roles);
        }
        if(user && isAuthenticated){
            setSiteUser(user);
            fetchIdtokenClaims();
        }
    }, [user]);


const value = {
    siteUser: siteUser,
    isAuthenticated: isAuthenticated,
    roles: roles,
    isAPIConfigured: isAPIConfigured
}
  return <UserContext.Provider children={children} value={value} />
}

export {UserProvider, useUserData}