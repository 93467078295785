import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Grid, Button, Switch, FormControlLabel, TextField, Typography, debounce, Accordion, AccordionSummary,
    AccordionDetails, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { DefaultNuggetFilters } from '../../constants/DefaultNuggetFilters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useActiveSession } from '../../Hooks/ActiveSessionProvider';
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";

const sliderStyle = {  // Give the slider some width
    position: 'relative',
    width: '100%',
    height: 80,
    border: '1px solid steelblue',
  }
  
  const railStyle = {
    position: 'absolute',
    width: '100%',
    height: 10,
    marginTop: 35,
    borderRadius: 5,
    backgroundColor: '#8B9CB6',
  }

const NuggetContainerActions = ({ onApplyFilters, onRefreshFeed, onArchiveSession, showOnPreviousSession, onPreviousSession, showOnNextSession, onNextSession, nuggetCount }) => {
    const [archiveDialogOpen, setArchiveDialogOpen] = React.useState(false);
    const { isModerator, session, saveSessionRules, refreshPage } = useActiveSession();
    const [appliedFilters, setAppliedFilters] = useState({ ...DefaultNuggetFilters })
    const debouncedApplyFilters = useRef(debounce((filters) => applyFilters(filters), 250)).current;


    useEffect(() => {
        debouncedApplyFilters(appliedFilters);
    }, [appliedFilters])

    const handleFilterByAnswered = () => {
        let updatedFilters = { ...appliedFilters, 'showAnswered': !appliedFilters['showAnswered'] }
        setAppliedFilters({ ...updatedFilters });
    }

    const handleApplyTextContentFilter = (e) => {
        var textFilter = e.target.value;
        if (textFilter !== undefined) {
            let updatedFilters = { ...appliedFilters, 'textContent': textFilter };
            setAppliedFilters({ ...updatedFilters });
        }
    }

    const handleApplyUsernameFilter = (e) => {
        let userNameFilter = e.target.value;
        if (userNameFilter !== undefined) {
            let updatedFilters = { ...appliedFilters, 'userName': userNameFilter }
            setAppliedFilters({ ...updatedFilters });
        }
    }

    const handleToggleTipeee = (e) => {
        let val = !appliedFilters['showTipeee'];
        let updatedFilters = { ...appliedFilters, 'showTipeee': val };
        setAppliedFilters({ ...updatedFilters })
    }

    const handleToggleYoutube = (e) => {
        let val = !appliedFilters['showYoutube'];
        let updatedFilters = { ...appliedFilters, 'showYoutube': val };
        setAppliedFilters({ ...updatedFilters })
    }

    const handleToggleOther = (e) => {
        let val = !appliedFilters['showOther'];
        let updatedFilters = { ...appliedFilters, 'showOther': val };
        setAppliedFilters({ ...updatedFilters })
    }

    const applyFilters = (filters) => {
        appliedFilters && onApplyFilters && onApplyFilters(filters);
    }

    const openArchiveDialog = () => {
        setArchiveDialogOpen(true);
    }

    const handleArchiveDialogCancel = () => {
        setArchiveDialogOpen(false);
    }

    const handleArchiveDialogCloseDoIt = () => {
        setArchiveDialogOpen(false);
        onArchiveSession();
    }

    const addNewRuleTier = (e) => {
        session.ruleList.push({LowerPriceThreshold: 0, UpperPriceThreshold: 0, CharacterLimit: 250, AllowCustomTags: false})
        //TODO: This makes the refresh awfully slow. There is definitely a better way.
        refreshPage()
    }

    const deleteRuleTier = (index) => {
        var temp = []
        for (var i = 0; i < session?.ruleList?.length; i++) {
            if (i != index)
                temp.push(session?.ruleList[i])
        }
        session.ruleList = temp
        refreshPage()
    }

    const renderSessionDetails = () => {
        if (session?.ruleList?.length > 0) {
            return session?.ruleList?.map((rule, index) => {
                return (
                    <Grid container item key={index} flexDirection='row' justifyContent='flex-start' pb={'1rem'} xs={12}>
                        <Grid item key={index+"1"}>
                            <TextField label={<Typography>Lower price limit</Typography>} value={rule.lowerPriceThreshold} onChange={(e) => {
                                session.ruleList[index].lowerPriceThreshold = e.target.value
                                refreshPage()
                            }} />
                        </Grid>
                        <Grid item key={index+"2"}>
                            <TextField label={<Typography>Upper price limit</Typography>} value={rule.upperPriceThreshold} onChange={(e) => {
                                session.ruleList[index].upperPriceThreshold = e.target.value
                                refreshPage()
                            }} />
                        </Grid>
                        <Grid item key={index+"3"}>
                            <TextField label={<Typography>Character limit</Typography>} value={rule.characterLimit} onChange={(e) => {
                                session.ruleList[index].characterLimit = e.target.value
                                refreshPage()
                            }}/>
                        </Grid>
                        <Grid item key={index+"4"}>
                            <IconButton onClick={(e) => deleteRuleTier(index)} color='primary'>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            })
            
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <>
            {isModerator ? 
                <>
                    <Button color='primary' style={{visibility: showOnPreviousSession() ? 'visible' : 'hidden'}} onClick={() => { onPreviousSession && onPreviousSession() }} sx={{ mb: '2rem' }}>Previous Session</Button>
                    <Button color='primary' style={{visibility: showOnNextSession() ? 'visible' : 'hidden'}} onClick={() => { onNextSession && onNextSession() }} sx={{ mb: '2rem' }}>Next Session</Button>
                    <Button color='primary' style={{visibility: showOnNextSession() ? 'hidden' : 'visible'}} onClick={openArchiveDialog} sx={{ mb: '2rem' }}>Archive</Button>
                    <Dialog open={archiveDialogOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            {"Archive current session?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will clear all non-anchored comments from the active session.
                            They will still be available in the session history. Are you sure?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleArchiveDialogCancel}>Cancel</Button>
                        <Button onClick={handleArchiveDialogCloseDoIt} autoFocus>
                            Do it
                        </Button>
                        </DialogActions>
                    </Dialog>
                </> :
                <></>
            }
            <Button color='primary' onClick={() => { onRefreshFeed && onRefreshFeed() }} sx={{ mb: '2rem' }}>Refresh</Button>

            {isModerator ?
                <Accordion sx={{ mb: '2rem' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={"nugget-controls-panel"}>
                        Edit Session
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container flexDirection='column' spacing={2} justifyContent={'space-between'}>
                            {renderSessionDetails()}
                            <Grid container flexDirection='row' spacing={2} justifyContent={'left'}>
                                <Grid item>
                                    <IconButton onClick={addNewRuleTier} color='primary' title='Add New Tier'>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => {saveSessionRules(session)}} color='primary' title='Save'>
                                        <SaveIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> : <></>
            }

            {nuggetCount > 0 ? <Accordion sx={{ mb: '2rem' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={"nugget-controls-panel"}
                >
                    Filters
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container flexDirection='row' spacing={2} justifyContent={'space-between'}>
                        <Grid container item flexDirection='column' justifyContent='flex-start' pb={'1rem'} xs={6}>
                            <Grid item>
                                <FormControlLabel control={<Switch checked={appliedFilters['showAnswered']} onChange={(e) => { handleFilterByAnswered() }} />} label='Show answered' />
                            </Grid>
                            <Grid item>
                                <FormControlLabel control={<Switch checked={appliedFilters['showTipeee']} onChange={(e) => { handleToggleTipeee(e) }} />} label='Show Tipeee' />
                            </Grid>
                            <Grid item>
                                <FormControlLabel control={<Switch checked={appliedFilters['showYoutube']} onChange={(e) => { handleToggleYoutube(e) }} />} label='Show Youtube' />
                            </Grid>
                            <Grid item>
                                <FormControlLabel control={<Switch checked={appliedFilters['showOther']} onChange={(e) => { handleToggleOther(e) }} />} label='Show Other' />
                            </Grid>
                            <Grid item>

                            </Grid>
                        </Grid>
                        <Grid container item flexDirection='column' spacing={2} justifyContent='flex-end' xs={6}>
                            <Grid item md={12}>
                                <TextField label={<Typography>Filter by text content</Typography>} value={appliedFilters['textContent']} onChange={(e) => handleApplyTextContentFilter(e)} />
                            </Grid>
                            <Grid item md={12}>
                                <TextField label={<Typography>Filter by user</Typography>} value={appliedFilters['userName']} onChange={(e) => handleApplyUsernameFilter(e)} />
                            </Grid>
                        </Grid>
                    </Grid>

                </AccordionDetails>
            </Accordion> : <></>}
        </>
    )
}

export default NuggetContainerActions