import CommentsService from "./Comment";
import SessionService from "./Session";
export const APIFactory = (() =>  {

    var repositories = {
        "comments": new CommentsService(),
        "session": new SessionService(),
    }

    const get =  (name) => {
        
        return repositories[name];
    }
    return {repositories, get}
})()
