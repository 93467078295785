import React, {useState} from 'react'
import {Box, Breakpoint, Container, Grid, Typography} from '@mui/material'
import { Outlet } from 'react-router-dom'
import Nav from './Nav'
import Drawer from '@mui/material/Drawer';
import NavButton from '../Buttons/NavButton';
import { LoginButton } from '../Buttons';

const Main = () => {
  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  return (
    <Box id="root">
      <Grid container flex-direction='column'>
        <Grid item xs={12}>
          <Nav onMenuClick={() => {setShowNavigationMenu(true)}}/>
        </Grid>
        <Grid item xs={12} >
            <Container component={'main'} sx={{py: '2rem'}}>
              <Outlet />
            </Container>
          </Grid>
        </Grid>
        <Drawer open={showNavigationMenu} id={'menu'} sx={{...menuStyles}} onClose={() => {setShowNavigationMenu(false)}}>
            <Grid container columns={1}>
            {/* <Header>
              <Mobile MobileHeader = {Header} > {showNavigationMenu} ? />
            </Header> */}
            <Grid item sx={{py: '2rem'}} width='100%'>     
             {/* <NavButton labelText={'ProfileIcon'} onClick ={() => {alert("Coming soon!")}} color = "primary.main" /> */}
             <Typography>Placeholder</Typography>
            </Grid>
            <Grid item xs={1}>
              <LoginButton color="primary.main"/>
            </Grid>
            <Grid item xs={1}>
             <NavButton labelText={'FAQs'} onClick={() => {alert("Coming soon!")}} color="primary.main"/>
            </Grid>
            <Grid item xs = {1}>
              <NavButton labelText={'Current Session'} onClick={() => {alert("Coming soon!")}} color="primary.main" />
            </Grid>
          </Grid>

        </Drawer>
    </Box>
  )
}

const menuStyles = {
  ".MuiPaper-root": {
    ".MuiGrid-root":{
      "> .MuiGrid-root": {
        borderBottom: '1px solid',
        borderColor: 'primary.accent',
        ".nav-button": {
          justifyContent: 'left'
        }
      }
    }
  }
}


export default Main