import React from 'react'
import {Button} from '@mui/material'


const NavButton = ({labelText, onClick, buttonId, color, sx}) => {
  let defaultColor = 'primary.contrastText'
  return (
    <Button variant="text" className={'nav-button'} onClick={onClick} id={buttonId ?? ''} sx={{...sx, color: color ? color : defaultColor, width: '100%', height: '100%', py: '1rem'}} >{labelText}</Button>
  )
}

export default NavButton