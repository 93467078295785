import './App.css';
import {LoginButton, LogoutButton} from './components/Buttons';
import {BrowserRouter, Routes, Route, Router} from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react'
import {AuthProvider} from './Hooks/AuthProvider'
import Main from './components/chrome/Main'
import {ActiveSessionPage} from './Pages'
import { UserProvider } from './Hooks/UserProvider';
import { ThemeProvider } from '@mui/material';
import Theme from './Themes/DefaultTheme'

const App = () => {
  return (
    <BrowserRouter>
        <AuthProvider>
          <UserProvider>
            <ThemeProvider theme={Theme}>
            <MainScreen />
            </ThemeProvider>
          </UserProvider>
        </AuthProvider>
    </BrowserRouter>
  )
}

const MainScreen = () => {
  return (
    <Routes>
      <Route element={<Main />}>
        <Route path="/:customerToken/Active" element={<ActiveSessionPage />}/>
        <Route path="/:customerToken" element={<ActiveSessionPage />}/>
      </Route>
    </Routes>
  );
}

export default App;
