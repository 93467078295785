import React from 'react'
import NavButton from './NavButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Defaults } from '../../constants/Defaults';
const LoginButton = ({ color, sx, customerToken }) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const { appUrl, environment } = Defaults
    const loginWithRedirectOverride = () => {
        const tokenDict = {
            "testecontainer": "TesteContainer",
            "mattandblondeshow": "MattAndBlondeShow",
            "timcast": "Timcast"
        }
        if (tokenDict[customerToken.toLowerCase()])
            customerToken = tokenDict[customerToken.toLowerCase()]
        loginWithRedirect({ redirectUri: `${appUrl}${customerToken}` });
    } 

    return !isAuthenticated && (
        <NavButton labelText={'Log In'} onClick={() => { loginWithRedirectOverride() }} color={color} sx={{ ...sx }} />
    )
}

export default LoginButton