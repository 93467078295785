import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

export var StatusCode = {
    Unauthorized: 401,
    Forbidden: 403,
    TooManyRequests: 429,
    InternalServerError: 500,
}

const reactAppBaseAPIUrl = process.env.REACT_APP_BASE_API_URL;

export var headers = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Credentials": true,
    "X-Requested-With": "XMLHttpRequest",
}

export var httpClient = (() => {
    var globalAccessToken = "";

    var instance = () => {
        var client = axios.create({
            baseURL: reactAppBaseAPIUrl,
            headers,
            withCredentials: true,
        });
        client.interceptors.response.use(
            (response) => response,
            (error) => {
                let {response} = error;
                return handleError(response);
            }
        );

        const injectGlobalAccessToken = (config) => {
            if(config && config.headers){
                //console.log(`Bearer ${globalAccessToken}`)
                config.headers.Authorization = `Bearer ${globalAccessToken}`
            }
            return config;
        }

        client.interceptors.request.use(injectGlobalAccessToken, (error) => Promise.reject(error));

        return client;
    }

    function handleError(error) {
        var {status} = error;
    
        switch(status) {
            case StatusCode.InternalServerError: {
                break;
            }
        }
    
        return Promise.reject(error);
    
    
    }
    
    return {
        request(config){
            return instance().request(config);
        },

        get(url, config){
            return instance().get(`${reactAppBaseAPIUrl}${url}` , config);
        },

        post(url, data, config){
            return instance().post(`${reactAppBaseAPIUrl}${url}`, data, config);
        },

        delete(url, config){
            return instance().delete(url, config);
        },
        configureClient(accessToken){
            globalAccessToken = accessToken;
        }
    }
})();

