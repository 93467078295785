import React from 'react'
import { SvgIcon } from '@mui/material'
import TipeeLogo from '../../Images/TipeeLogo.svg'
const TippeeeIcon = (props) => {
    return (
        <svg style={ {'.st0': { fill: '#E02f44'}, enableBackground:'new 0 0 846.56 178.82', width: 'auto', height: 'auto'}} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
            viewBox="0 0 846.56 178.82">
            <g height='.5rem' width='.5rem'>
                <g>
                    <path className="st0" d="M122.55,111.7h-7.11V38.76c0-1.24-1-2.25-2.24-2.25L75.48,47.84c-1.73,0.43-2.24,2.04-2.24,3.28v21.64
			c0,1.24,1,2.25,2.24,2.25l6.74-2.03v38.71h-7.11c-1.24,0-2.24,1.01-2.24,2.25v22.67c0,1.24,1,2.25,2.24,2.25h47.43
			c1.24,0,2.24-1.01,2.24-2.25v-22.67C124.79,112.71,123.79,111.7,122.55,111.7z"/>
                </g>
                <g>
                    <path className="st0" d="M98.83,33.24c9.18,0,16.62-7.44,16.62-16.62C115.45,7.44,108.01,0,98.83,0c-9.18,0-16.62,7.44-16.62,16.62
			C82.21,25.8,89.65,33.24,98.83,33.24z"/>
                </g>
                <g>
                    <path className="st0" d="M59.67,36.51H42.32c0-0.58,0-1.16,0-1.75v-22.5c0,0,0.56-6.32-6.17-3.97c-6.74,2.34-22.21,6.85-22.21,6.85
			s-3.59,0.52-3.59,5.19c0,3.94,0.06,6.22,0.11,16.19H2.24C1,36.51,0,37.52,0,38.76v22.67c0,1.24,1,2.25,2.24,2.25h8.31
			c0.02,12.06,0.04,28.02,0.04,49.41c0,0-2.03,40.04,48.55,20.32c0,0,2.55-1.38,2.75-3.59v-21.95c0,0,0.03-3.36-3.11-1.85
			c0,0-16.46,7.5-16.46-3.25c0-8.63,0-9.05,0-39.09h17.35c1.24,0,2.24-1.01,2.24-2.25V38.76C61.91,37.52,60.91,36.51,59.67,36.51z"
                    />
                </g>
                <g>
                    <path className="st0" d="M184.27,36.51c-5.42,0-10.61,1.1-15.41,3.12v-0.95c0-1.24-1-2.25-2.24-2.25h-38.25
			c-1.24,0-2.24,1.01-2.24,2.25v22.67c0,1.24,1,2.25,2.24,2.25h7.27v88.04h-7.27c-1.24,0-2.24,1.01-2.24,2.25v22.67
			c0,1.24,1,2.25,2.24,2.25h47.6c1.24,0,2.24-1.01,2.24-2.25V153.9c0-1.24-1-2.25-2.24-2.25h-7.11v-15.9
			c4.81,2.02,10,3.12,15.41,3.12c24.7,0,44.73-22.91,44.73-51.18S208.97,36.51,184.27,36.51z M184.78,112.33
			c-8.86-0.04-15.98-11.1-15.92-24.71c0.06-13.61,7.29-24.61,16.14-24.57c8.85,0.04,15.98,11.1,15.92,24.71
			C200.86,101.37,193.63,112.37,184.78,112.33z"/>
                </g>
                <g>
                    <path className="st0" d="M837.35,138.87H447.08c-5.08,0-9.21-4.13-9.21-9.21V45.57c0-5.08,4.13-9.21,9.21-9.21h390.27
			c5.08,0,9.21,4.13,9.21,9.21v84.09C846.56,134.74,842.43,138.87,837.35,138.87z M447.08,42.83c-1.51,0-2.73,1.23-2.73,2.73v84.09
			c0,1.51,1.23,2.73,2.73,2.73h390.27c1.51,0,2.73-1.23,2.73-2.73V45.57c0-1.51-1.23-2.73-2.73-2.73H447.08z"/>
                </g>
                <g>
                    <g>
                        <path className="st0" d="M469.18,105.39v-4.84h19.22c1.24,0,1.86-0.62,1.86-1.86v-4.53c0-1.16-0.52-1.84-1.55-2.05l-12.09-2.42
				c-4.96-0.99-7.44-4.32-7.44-9.98v-3.47c0-6.94,3.47-10.41,10.41-10.41h14.2l4.15,3.22v4.84h-17.98c-1.24,0-1.86,0.62-1.86,1.86
				v4.03c0,1.16,0.52,1.84,1.55,2.05l12.09,2.42c4.96,0.99,7.44,4.32,7.44,9.98v3.97c0,6.94-3.47,10.42-10.42,10.42h-15.44
				L469.18,105.39z"/>
                    </g>
                    <g>
                        <path className="st0" d="M524.98,73.89v-8.06h34.97v8.06h-13.02v34.72H538V73.89H524.98z" />
                    </g>
                    <g>
                        <path className="st0" d="M587.6,108.61V65.83h20.83c6.94,0,10.41,3.47,10.41,10.41v7.07c0,5.17-1.92,8.41-5.76,9.73l7,13.7
				l-1.05,1.86h-7.69l-7.56-14.88h-7.26v14.88H587.6z M596.52,85.67h11.53c1.24,0,1.86-0.62,1.86-1.86v-8.06
				c0-1.24-0.62-1.86-1.86-1.86h-11.53V85.67z"/>
                    </g>
                    <g>
                        <path className="st0" d="M648.85,108.61V65.83h29.26v8.06h-20.33v8.68h18.47v8.06h-18.47v9.92h20.33v8.06H648.85z" />
                    </g>
                    <g>
                        <path className="st0" d="M703.4,106.94l12.71-41.1h13.64l12.71,41.1l-1.12,1.67h-7.44l-2.61-8.68h-16.74l-2.6,8.68h-7.44
				L703.4,106.94z M716.98,91.87h11.9l-5.45-17.98h-0.99L716.98,91.87z"/>
                    </g>
                    <g>
                        <path className="st0" d="M769.74,108.61V65.83h10.66l12.09,25.85l12.09-25.85h10.66v42.78h-8.93V81.58l-10.04,21.45h-7.56
				l-10.05-21.45v27.03H769.74z"/>
                    </g>
                </g>
                <path className="st0" d="M441.1,93.63c-2.95,2.28-5.98,4.46-9.18,6.66c-9.09,6.24-18.67,11.78-28.6,16.55
		c-8.48,4.08-17.43,7.62-27.21,9.66c-1,0.21-2.01,0.39-3.01,0.55c-0.1,0.02-0.48,0.07-0.74,0.11c-0.36,0.04-0.71,0.08-1.07,0.12
		c-2.02,0.2-4.06,0.26-6.08,0.14c-0.13-0.01-0.71-0.08-0.93-0.1c-0.29-0.05-0.58-0.11-0.87-0.17c-0.76-0.16-1.5-0.39-2.24-0.65
		c0.73,0.24-1.16-0.58-1.54-0.8c-0.15-0.09-0.3-0.19-0.45-0.28c0.12,0.07-0.7-0.54-0.9-0.73c-0.24-0.22-1.11-1.23-1.24-1.34
		c-0.25-0.36-0.49-0.74-0.72-1.12c8.2-5.73,15.8-12.41,22.71-19.59c7.99-8.3,15.38-17.64,20.61-27.94
		c5.1-10.05,8.95-23.91,0.65-33.38c-8.56-9.77-22.74-2.75-30.6,3.86c-7.87,6.62-13.51,15.63-17.77,24.88
		c-4.11,8.93-7.28,18.35-8.87,28.06c-1.01,6.15-1.6,12.82-0.6,19.14c-3.51,2.08-7.13,3.98-10.86,5.64
		c-4.22,1.87-9.71,3.5-14.72,4.29c0.31-0.06-1.17,0.13-1.17,0.13c-0.6,0.06-1.21,0.09-1.81,0.13c-1.15,0.06-2.3,0.06-3.44,0.01
		c-0.32-0.01-0.64-0.04-0.97-0.07c0.33,0.02-0.69-0.1-0.89-0.14c-0.89-0.17-1.76-0.42-2.62-0.72c0.54,0.17-1.25-0.61-1.61-0.82
		c-0.17-0.1-0.33-0.2-0.49-0.31c-0.16-0.11-0.45-0.33-0.56-0.43c-0.5-0.43-0.95-0.92-1.38-1.42c-0.11-0.14-0.23-0.29-0.29-0.38
		c-0.29-0.41-0.54-0.85-0.79-1.28c9.16-6.25,17.6-13.57,25.03-21.8c9.13-10.13,17.37-21.94,22.41-34.67
		c4.11-10.37,2.95-23.64-8.64-28.14c-5.19-2.01-11.06-0.37-15.9,1.73c-6.58,2.85-12.12,8.32-16.61,13.77
		c-5.84,7.08-9.89,15.4-13.29,23.87c-3.64,9.09-6.08,18.68-6.94,28.44c-0.33,3.75-0.34,7.75,0.21,11.64
		c-4.41,2.61-8.98,4.96-13.68,7.01c-0.29,0.11-1.53,0.63-1.9,0.78c-0.59,0.23-1.19,0.46-1.79,0.68c-0.53,0.19-1.06,0.38-1.6,0.55
		c-1.12,0.33-2.24,0.63-3.38,0.9c-0.53,0.12-1.06,0.23-1.6,0.33c-0.11,0.02-0.72,0.11-0.95,0.16c-1.79,0.2-3.59,0.23-5.38,0.07
		c-0.05-0.01-0.09-0.02-0.15-0.03c-0.39-0.07-0.77-0.16-1.15-0.25c-0.51-0.12-1.01-0.29-1.51-0.46c0.16,0.03-1.17-0.56-1.49-0.75
		c-0.29-0.17-0.57-0.36-0.85-0.55c-0.06-0.05-0.12-0.11-0.21-0.18c-0.53-0.46-1.01-0.97-1.47-1.49c-0.03-0.03-0.07-0.08-0.11-0.13
		c-0.16-0.22-0.31-0.44-0.46-0.66c-0.4-0.61-0.75-1.25-1.08-1.89c-0.07-0.13-0.12-0.26-0.18-0.39c-0.09-0.21-0.34-0.79-0.41-0.99
		c-0.04-0.11-0.07-0.22-0.11-0.34c15.11-9.51,27.93-22.37,37.33-37.76c6.41-10.51,14.04-24.87,7.69-37.1
		c-5.74-11.06-19.86-8.51-28.46-2.92c-15.89,10.33-23.84,28.96-27.76,46.8c-1.63,7.44-2.99,16.62-2.14,25.22
		c-2.7,1.44-5.49,2.7-8.29,3.92c-2.93,1.28-3.58,5.52-2.11,8.03c1.76,3,5.1,3.38,8.03,2.11c1.78-0.78,3.53-1.6,5.27-2.46
		c1.4,3.12,3.34,5.96,5.96,8.33c7.74,7.03,18.65,6.6,28.09,3.8c6.79-2.11,13.4-5.26,19.66-8.89c0.26,0.42,0.52,0.84,0.8,1.25
		c5.52,7.97,14.68,10.42,23.97,9.63c10.93-0.92,21.33-5.17,30.81-10.59c3.99,6.3,10.29,9.87,18.18,10.65
		c8.77,0.88,18.17-1.8,26.38-4.6c17.79-6.06,34.74-15.39,50.04-26.37V93.63z M246.49,98.52c0.01-0.09,0.06-0.42,0.09-0.68
		c0.06-0.4,0.12-0.8,0.18-1.19c0.15-0.99,0.33-1.97,0.52-2.96c0.4-2.14,0.9-4.27,1.43-6.39c1.02-3.98,2.28-7.9,3.75-11.74
		c0.29-0.75,0.59-1.5,0.9-2.24c0.02-0.02,0.71-1.61,0.85-1.92c0.78-1.7,1.63-3.36,2.53-5c0.76-1.37,1.58-2.7,2.45-3.99
		c0.24-0.35,0.48-0.69,0.73-1.04c-0.05,0.09,0.94-1.17,0.9-1.13c0.85-1.04,1.76-2.03,2.68-3.01c4.05-4.34,8.82-8,14.13-9.1
		c0.05-0.01,0.08-0.02,0.13-0.03c0.09,0,0.22-0.01,0.42-0.02c0.5-0.03,0.99-0.01,1.49,0.01c0,0,0.01,0,0.01,0
		c0.07,0.02,0.16,0.04,0.28,0.07c0.25,0.05,0.5,0.13,0.74,0.21c-0.36-0.11,0.47,0.24,0.7,0.37c0.04,0.02,0.07,0.04,0.1,0.06
		c0.03,0.02,0.05,0.04,0.08,0.07c0.11,0.1,0.41,0.44,0.55,0.58c0.13,0.2,0.25,0.39,0.36,0.6c0.03,0.09,0.33,0.71,0.39,0.89
		c0.16,0.49,0.26,0.99,0.37,1.49c0.01,0.05,0.02,0.08,0.03,0.12c0,0.09,0.01,0.25,0.02,0.5c0.04,0.59,0.03,1.18,0.01,1.77
		c-0.05,2.5-0.89,5.27-1.96,8.14c-2.52,6.74-6.72,13.2-10.82,19.06c-3.79,5.41-8.24,10.35-13.04,14.89
		c-3.58,3.39-7.46,6.48-11.53,9.3C245.99,103.65,246.19,101.08,246.49,98.52z M298.51,107.18c0.05-1.06,0.14-2.12,0.24-3.17
		c0.04-0.41,0.09-0.81,0.14-1.22c-0.01,0.11,0.19-1.38,0.22-1.56c0.74-4.71,1.92-9.34,3.35-13.88c2.68-8.52,5.81-15.7,11.03-23.56
		c0.5-0.76,1.03-1.49,1.56-2.23c0.05-0.07,0.1-0.13,0.13-0.18c0.51-0.65,1.05-1.28,1.59-1.91c2.35-2.77,4.99-5.41,7.96-7.52
		c2.26-1.6,4.33-2.66,6.46-3.32c0.55-0.17,1.11-0.29,1.67-0.41c0.07-0.01,0.12-0.03,0.18-0.04c0.09,0,0.21-0.01,0.38-0.02
		c0.5-0.04,1-0.02,1.5,0c0.09,0,0.15,0,0.2,0c0.1,0.03,0.27,0.08,0.55,0.15c0.14,0.03,0.52,0.18,0.73,0.25
		c0.22,0.12,0.44,0.25,0.65,0.38c0.04,0.04,0.1,0.1,0.17,0.16c0.1,0.1,0.36,0.4,0.5,0.56c0.15,0.24,0.29,0.48,0.42,0.72
		c0.03,0.05,0.05,0.09,0.08,0.14c0.02,0.06,0.04,0.12,0.07,0.2c0.17,0.47,0.28,0.96,0.39,1.45c0.02,0.13,0.04,0.23,0.06,0.32
		c0.02,0.27,0.05,0.53,0.06,0.8c0.29,5.24-2.52,10.85-5.33,15.98c-8.7,15.92-20.86,29.4-35.03,39.92
		C298.46,108.52,298.48,107.85,298.51,107.18z M353.8,106.67c0.06-1.06,0.16-2.13,0.27-3.19c0.01-0.09,0.1-0.83,0.12-1.03
		c0.06-0.4,0.12-0.81,0.18-1.21c0.38-2.42,0.89-4.82,1.47-7.21c1.14-4.68,2.64-9.26,4.38-13.74c0.21-0.53,0.42-1.06,0.64-1.59
		c0.06-0.15,0.1-0.25,0.14-0.34c0.04-0.09,0.08-0.19,0.15-0.34c0.44-1.02,0.9-2.03,1.37-3.04c0.98-2.09,2.04-4.14,3.16-6.16
		c0.96-1.72,1.99-3.39,3.07-5.03c0.5-0.76,1.03-1.49,1.56-2.23c0.05-0.07,0.1-0.13,0.13-0.18c0.73-0.92,1.5-1.81,2.27-2.69
		c3.94-4.5,9.04-8.82,14.16-10.16c0.11-0.03,0.88-0.16,1.05-0.2c0.34-0.02,0.68-0.03,1.03-0.04c0.14,0,0.58,0.05,0.82,0.07
		c0.28,0.07,0.55,0.14,0.82,0.22c0.03,0.01,0.04,0.01,0.06,0.02c0.04,0.02,0.08,0.05,0.14,0.08c0.13,0.07,0.51,0.32,0.68,0.43
		c0.18,0.17,0.35,0.34,0.51,0.52c0.01,0.01,0.02,0.02,0.03,0.03c0.13,0.2,0.26,0.39,0.38,0.6c0.07,0.11,0.28,0.59,0.38,0.78
		c0.11,0.33,0.21,0.66,0.3,1c0.12,0.45,0.21,0.91,0.28,1.36c-0.15-0.8,0.03,0.85,0.03,0.95c0.13,2.93-0.35,4.97-1.26,8.01
		c-1.48,4.96-4.14,9.56-6.91,13.9c-6.29,9.88-14.39,18.61-23.24,26.54c-2.66,2.39-5.43,4.68-8.28,6.85
		C353.71,108.65,353.74,107.66,353.8,106.67z"/>
            </g>
        </svg>
    )

}

export default TippeeeIcon