import { useState, useEffect } from 'react'
import { useUserData } from '../../Hooks/UserProvider';
import { Grid, IconButton } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import AnchorIcon from '@mui/icons-material/Anchor';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import { useActiveSession } from '../../Hooks/ActiveSessionProvider';
const NuggetControls = ({ nugget, onToggleAnswered }) => {

    const {markNuggetAnswered, markNuggetUnanswered, toggleNuggetDoNotArchive, isModerator} = useActiveSession();
    const [answered, setAnswered] = useState(nugget?.isAnswered);
    const [doNotArchive, setDoNotArchive] = useState(nugget?.doNotArchive);

    useEffect(() => {
        if(nugget){
            setAnswered(nugget.isAnswered);
        }
    }, [nugget]);

    const onMarkAsAnswered = (e) => {
        var markAnswered = !answered;
        if (isModerator && markNuggetAnswered) {
            
            if(markAnswered){
                markNuggetAnswered(nugget?.nuggetID);
                onToggleAnswered && onToggleAnswered()
            } else {
                markNuggetUnanswered(nugget?.nuggetID);
            }
            setAnswered(markAnswered);
        }
    }

    const onDoNotArchive = (e) => {
        if (isModerator && toggleNuggetDoNotArchive) {
            toggleNuggetDoNotArchive(nugget?.nuggetID)
            setDoNotArchive(!doNotArchive);
        }
    }


    const onLikeNugget = (e) => {
        alert("coming soon")
    }

    return (
        <Grid container spacing={2} sx={{ color: 'nuggets.controls' }}>
            <Grid item color='inherit'>
                <IconButton onClick={onLikeNugget} color='inherit'>
                    {nugget?.iLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
                </IconButton>
                {isModerator ?
                    <>
                        <IconButton onClick={onMarkAsAnswered} color='inherit'>
                            {answered ? <MarkChatReadIcon /> : <MarkChatReadOutlinedIcon />}
                        </IconButton>
                        <IconButton onClick={onDoNotArchive} color='inherit'>
                            {doNotArchive ? <AnchorIcon /> : <AnchorIcon sx={{ color: 'nuggets.disabledControls' }} />}
                        </IconButton>
                    </> :
                    <></>
                }
            </Grid>
        </Grid>
    )
}

export default NuggetControls