import React from 'react'

const RenderIf = ({condition, children}) => {
 
    if(condition === true){
        return (
            <>{children}</>
        )
    }
    else {
        return
    }
}

export default RenderIf